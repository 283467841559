import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  colors: {
    primary: '#a266df', // primary
    secondary: '#2ea26f',
    tertiary: '#146aff',
    quaternary: '#F75D75',
    gray: '#3c3a43',
    title: '#ffffff',
    background: '#EBEBEB',
    error: '#FF4252',
  },
  shadow: 'rgb(0 0 0 / 10%) 0px 15px 45px 0px',
  fontSize: {
    tiny: '1rem',
    small: '1.3rem',
    medium: '1.825rem',
    mediumLarge: '2.825rem',
    large: '4.25rem',
    huge: '6rem'
  }
};

export type ThemeType = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<ThemeType>;