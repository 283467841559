import { createGlobalStyle } from 'styled-components';
import { ThemeType } from 'styled';

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
 html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body{
    margin: 0;
    overflow: hidden;
    padding-bottom: 100px;
    background-color: ${({ theme }): string => theme.colors.background};
  }

  #root {
    overflow: hidden;
    width: calc(100% - 150px);
    min-height: calc(100vh - 150px);
    margin: 75px auto;
    border-radius: 15px;
  }

  // ########################
  // ## Default Input layout
  // ########################
  input {
    background-color: transparent;
    outline: 0;
    display: block;
    border: none;
    border-radius: none;
    padding: 10px 12px;
    min-width: 200px;
    width: 100%;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.small};
    margin: 15px 0;
    &::placeholder {
      font-size: ${({ theme }) => theme.fontSize.small};
      font-weight: 600;
    }
  }
  // Remove default webkit search field styling
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }


  a, p, div, li, h1, h2, h3, h4, h5, h6, header, footer, form, input {
    font-weight: 400; /* Default size */
    font-family: 'Lato', sans-serif;
    transition: background-color 0.2s linear;
    transition: box-shadow 0.125s linear;
    text-decoration: none;
    margin: 0;
    user-select: none;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.5;
    color: black;
  }
`;