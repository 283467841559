import { FC } from 'react';
import { styled } from 'styled';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  display: flex;
  height: 55vh;
  align-items: center;
  justify-content: center;
`;
const Introduction = styled.p`
  width: 50%;
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 1px;

  a {
    text-decoration: underline;
  }
`;

export const Home: FC = () => {
  return (
    <>
      <Helmet>
        <title>Flashflare | Home</title>
      </Helmet>
      <Container>
        <Introduction>
          Hi there, <br />
          I am <a href="https://linkedin.com/in/emielvanseveren" target="_blank" rel="noopener noreferrer">Emiel Van Severen </a>
          a student @UGent. I ❤️ Cloudflare. I saw a post on the official Discord
          requesting to test a hiring assignment, with some cool swag in return. Can't resist that 🔥. I've been wanting to learn Rust for a while now but kept struggling to find a small project to focus on. It is also seems a great opportunity to get an idea of what is expected from a new grad.
          <br />
          <br />
          So anyway, please hire me 🤖.
          <br /> Thank you, it was fun.
        </Introduction>
      </Container>
    </>
  );
}
