import { FC, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { styled } from 'styled';
import { TextField } from 'components';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { useValidationSchema } from 'hooks';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  padding: 2.5rem;
  h2 {
    color: black;
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  button {
    margin-top: 1.2rem;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: .5rem;
    padding: .7rem 1.2rem;
    cursor: pointer;
    background-color: ${({ theme }): string => theme.colors.primary};
  }
`;

type FormFields = {
  title: string;
  content: string;
  username: string;
}

export const CreatePost: FC = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const validationSchema = useMemo(
    () => yup.object().shape({
      title: yup.string().required('Title is a required field').min(5),
      content: yup.string().required('Content is required'),
      username: yup.string().required('Username is required')
    }), [])

  const { handleSubmit, control, formState } = useForm<FormFields>({
    resolver: useValidationSchema(validationSchema),
    mode: 'onChange'
  });

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    setLoading(true);

    try {
      // Could throw this in a singleton service.
      const response = await fetch(`${process.env.REACT_APP_API}/posts`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers
        });
      if (response.ok) {
        navigate('/feed');
      } else { /* todo: show notification that something went wrong.*/ }
    } catch (error) { /* todo: show notification that something went wrong. */ }
    setLoading(false);
  }

  return (
    <>
      <Container>
        <Helmet>
          <title>Flashflare | Create post</title>
        </Helmet>
        {loading && <p>Loading...</p>}
        <h2>Create a Post</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            control={control}
            error={formState.errors.title}
            name="title"
            labelText="Title"
            placeholder=""
          />
          <TextField
            control={control}
            error={formState.errors.content}
            name="content"
            labelText="Content"
            placeholder=""
          />
          <TextField
            control={control}
            error={formState.errors.username}
            name="username"
            labelText="username"
            placeholder=""
          />
          <button
            type="submit"
            onClick={() => { /* dummy (fixes bug in react-hook-form where form sometimes will not submit, if this is not present. */ }}
          >Create post</button>
        </form>
      </Container>
    </>
  )
}