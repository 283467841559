import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Frame, CreatePost, Home, Feed } from './pages';

export const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Frame>
        <Route element={<Home />} path="/" />
        <Route element={<CreatePost />} path="/post/create" />
        <Route element={<Feed />} path="/feed" />
      </Frame>
    </Routes>
  </BrowserRouter>
)