import { FC, StrictMode } from 'react';
import { GlobalStyle } from './styled/globalStyle';
import { Router } from './Router';
import { ThemeProvider } from 'styled-components';
import { theme } from './styled/theme';

export const App: FC = () => {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </StrictMode>
  );
}

