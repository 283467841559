import { FC } from 'react';
import { styled } from 'styled';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  height: 7.5rem;
  margin-bottom: 2rem;
  padding: 2.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* custom header */
  h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 900;
    letter-spacing: 2px;
    color: black;
    font-size: 2rem;
  }
`;

const Left = styled.div``;
const Right = styled.div`
  a {
    margin: 0 1.5rem;
  }
`;

export const Header: FC = () => {
  return (
    <Container>
      <Left>
        <Link to="/">
          <h1>Flashflare</h1>
        </Link>
      </Left>
      <Right>
        <Link to="/post/create">Create post</Link>
        <Link to="/feed">Feed</Link>
      </Right>
    </Container>
  );
}