import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Post, IPost } from 'components';

const Container = styled.div`
  max-height: 70vh; // should not be static.
  overflow-y: auto;
`;

export const Feed: FC = () => {
  const [posts, setPosts] = useState<IPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  async function getPosts() {

    try {
      const resp = await fetch(`${process.env.REACT_APP_API}/posts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const posts: IPost[] = await resp.json();
      setPosts(posts);
      setLoading(false);
    } catch (e) { /* todo: handle error */ }
  }

  function renderPosts() {
    if (!posts.length) {
      return <p>No posts yet 😥</p>;
    } else {
      const data = posts.map((post, index) => <Post key={index} {...post} />);
      return data;
    }
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <Helmet>
        <title>Flashflare | Feed</title>
      </Helmet>
      <Container>
        <div>
          <h2>Feed</h2>
          {
            loading
              ? <div>loading...</div>
              : renderPosts()
          }
        </div>
      </Container>
    </>
  )
}