import { FC } from 'react';
import styled from 'styled-components';

export interface IPost {
  username: string;
  title: string;
  content: string;
  timestamp: string;
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  width: 700px;
  margin: 2.5rem auto;
  border-radius: 8px;
  min-height: 250px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
`;

const Placeholder = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.colors.gray};
  margin-right: 1.5rem;
`;
const UserBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  p {
    font-size: 1.1rem;
    font-weight: 600;
  }
`;

// Could add timestamp to the post
export const Post: FC<IPost> = ({ username, title, content, timestamp }) => {
  return (
    <Container>
      <UserBlock><Placeholder /> <p>{username}</p></UserBlock>
      <h3>{title}</h3>
      <p>{content}</p>
    </Container>
  )
}