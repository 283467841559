import { FC } from 'react';
import { styled } from 'styled';
import { Header } from 'views';
import { Outlet } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  padding: 25px 100px;
  height: calc(100vh - 125px);
  background-color: white;
  border-radius: 15px;
`;
const ContentContainer = styled.div``;

export const Frame: FC = () => {
  return (
    <Container>
      <Header />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  )
}